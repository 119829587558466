var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-btn',{staticClass:"mx-2 a-view-action-btn",class:{
    active: _vm.active,
  },style:({
    background: `
    radial-gradient(
      circle,
      rgba(0, 0, 0, 0.1) 0%,
      ${_vm.hexToRgba(_vm.value.color, 0.3)} 100%
    )
    `,
  }),attrs:{"fab":"","dark":"","small":""},on:{"click":() => _vm.$emit('click'),"mouseover":() => _vm.$emit('mouseover'),"mouseleave":() => _vm.$emit('mouseleave')}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" "+_vm._s(_vm.value.icon)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }