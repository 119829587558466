<template>
  <fullscreen
    class="fullscreen"
    v-model="fullscreen"
    :class="{
      // maximized: maximized,
      hover: (hovered || active) && !value.isFocused,
    }"
    :style="extraStyle"
  >
    <div class="a-view-container pa-1 d-flex">
      <v-card
        class="a-view pa-0 ma-0 elevation-6"
        dark
        @mouseover="hovered = true"
        @mouseleave="hovered = false"
        @contextmenu="e=>$emit('contextmenu', e)"
        :class="{
          maximized: maximized,
          hover: hovered || active || value.isFocused,
        }"
      >
        <div class="app-bar-space">
          <v-expand-transition>
            <v-toolbar
              class="a-view-header elevation-0 transparent"
              dense
              :height="24"
              :class="{
                small: isSmall() && !value.isExpanded,
                active,
              }"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small icon @click="onSelect" v-bind="attrs" v-on="on">
                    <v-icon size="20" color="white">
                      {{ value.view.display.icon }}
                    </v-icon>
                  </v-btn>
                </template>
                <span> {{ value.view.display.description }}</span>
              </v-tooltip>
              <span class="a-view-name">
                {{ name ? name : value.view.display.name }}
              </span>
              <v-spacer></v-spacer>

              <v-btn
                v-if="(actions && actions.length) || hasSlot('actions')"
                @click="closed = !closed"
                icon
                small
                class="a-view-actions-activator"
              >
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
              <v-scale-transition>
                <div v-if="hasFilters" class="filters-active"></div>
              </v-scale-transition>

              <v-spacer></v-spacer>

              <slot v-if="hasSlot('top-actions')" name="top-actions"></slot>

              <v-btn v-if="!value.isExpanded" small icon @click="onExpand">
                <v-icon size="16"> mdi-arrow-expand-vertical</v-icon>
              </v-btn>

              <v-btn v-else small icon @click="onCollapse">
                <v-icon size="16"> mdi-arrow-collapse-vertical</v-icon>
              </v-btn>

              <v-btn small icon @click="onMaximize">
                <v-icon size="16">mdi-window-maximize</v-icon>
              </v-btn>
              <v-btn small icon @click="(e) => $emit('onClose', e)">
                <v-icon size="16">mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
          </v-expand-transition>

          <v-system-bar v-if="false">
            <v-icon @click="onSelect">mdi-dots-horizontal</v-icon>
            <span>{{ value && value.name }}</span>

            <v-spacer></v-spacer>
            <v-icon>mdi-minus</v-icon>
            <v-icon @click="openFullScreen">mdi-checkbox-blank-outline</v-icon>
            <v-icon @click="CloseApp">mdi-close</v-icon>
          </v-system-bar>
        </div>

        <v-card-text
          class="a-view-main-content"
          :style="{
            display: inProgress ? 'none' : '',
          }"
        >
          <transition name="slide-fade">
            <div
              class="content-wrapper"
              v-if="hasSlot('content')"
            :style="{
              opacity: pLoading ? 0 : 1,
            }"
            >
              <slot name="content"></slot>
            </div>
          </transition>
          <transition name="f-fade">
            <div
              class="content-wrapper loader-placeholder"
              v-if="hasSlot('content') && pLoading"
            >
              <v-skeleton-loader
                dark
                tile
                type="image"
                :style="{
                  width: '100%',
                }"
                :height="'100%'"
              ></v-skeleton-loader>
            </div>
          </transition>
        </v-card-text>

        <v-expand-transition>
          <div
            v-if="!closed"
            absolute
            class="a-view-actions-container"
            :background-color="'rgba(0,0,0,0.5)'"
            dark
            shift
          >
            <slot v-if="hasSlot('actions')" name="actions"></slot>

            <v-row
              v-else
              class="pa-0 ma-0 d-flex justify-center actions-wrapper"
            >
              <v-col cols="10">
                <v-slide-x-transition hide-on-leave leave-absolute>
                  <div :key="actionName" class="action-description">
                    <h2>{{ actionName }}</h2>
                    <span>
                      {{ activeAction ? activeAction.description : "-" }}</span
                    >
                  </div>
                </v-slide-x-transition>

                <v-row class="pa-0 ma-0 d-flex justify-center">
                  <v-col
                    cols="3"
                    class="d-flex justify-center"
                    v-for="(btn, i) in actions"
                    :key="i"
                  >
                    <AViewActionBtnVue
                      @mouseover="
                        () => {
                          activeAction = btn;
                          actionName = btn.name;
                        }
                      "
                      @click="onAction(btn)"
                      :active="activeAction.name === btn.name"
                      :value="btn"
                    >
                    </AViewActionBtnVue>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-expand-transition>
      </v-card>
    </div>
  </fullscreen>
</template>


<script>
import { GUI } from "../../../../views/src/GrapUI.config";
import AViewActionBtnVue from "./A-ViewActionBtn.vue";

export default {
  props: {
    value: {
      default: () => ({}),
    },
    active: {
      type: Boolean,
      default: false,
    },
    hasFilters: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: undefined,
    },
    actions: {
      type: Array,
      default: () => [],
    },
    payload: {
      type: Object,
      default: () => ({}),
    },
    permissions: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    AViewActionBtnVue,
  },
  data() {
    return {
      fullscreen: false,
      hovered: false,
      activeAction: {},
      actionName: "",

      closed: true,
      appOpened: false,
      selected: false,
      maximized: false,
      inProgress: false,

      pLoading: this.loading,

      extraStyle: {
        position: undefined,
        left: this.value.position.left + "px",
        top: this.value.position.top + "px",
        height: (this.value.height ? this.value.height : 50) + "px",
        // width: this.value.width + "px",
        // transition: "all 0.6s linear",
        zIndex: 1,
      },
      initialPosition: {
        top: this.value.position.top,
        left: this.value.position.left,
      },
    };
  },
  async mounted() {
    const { top, left } = this.value.position;

    this.extraStyle.left = left + "px";
    this.extraStyle.top = top + "px";

    setTimeout(() => {
      this.pLoading = false;
    }, 600);
  },
  computed: {},

  methods: {
    offsetLeft() {
      return this.maximized
        ? GUI.scene.breadcrumbs.active.left
        : this.value.position.left;
    },
    offsetTop() {
      return this.maximized
        ? GUI.scene.breadcrumbs.active.top
        : this.value.position.top;
    },
    async onAction(action) {
      console.log('action')

      if (action.handler && this.payload && Object.keys(this.payload).length)
        await action.handler(this.payload);
      else {
        this.$emit(action.on, action);
        this.$emit("action", action);
      }
      this.closed = true;
    },
    isSmall() {
      return this.value.height < 100;
    },
    onMaximize() {
      if (this.inProgress) return;
      this.inProgress = true;

      this.maximized = !this.maximized;

      if (this.maximized) return this.maximize();
      else return this.minimize();
    },
    maximize() {
      const { top, left } = this.value.position;
      console.log("top: ", top);
      console.log("left: ", left);

      this.initialPosition = {
        top: GUI.scene.breadcrumbs.active.top + top,
        left: GUI.scene.breadcrumbs.active.left + left,
      };

      //TODO: improve this terrible code...
      this.extraStyle.transition = "none!important";
      this.extraStyle.width = this.value.width + "px";

      setTimeout(() => {
        (this.extraStyle.transition = "all 0.3s linear"),
          (this.extraStyle.position = "absolute");
        this.extraStyle.zIndex = 9;

        this.extraStyle.left = GUI.scene.breadcrumbs.active.left + "px";
        this.extraStyle.top = GUI.scene.breadcrumbs.active.top + "px";
      }, 100);

      setTimeout(() => {
        // this.extraStyle.height = this.value.maxHeight + "px";
        // this.extraStyle.width = this.value.maxWidth + "px";

        this.extraStyle.height = "calc(100vh - 120px)";
        this.extraStyle.width = "100%";
      }, 400);

      setTimeout(() => {
        // this.extraStyle.height = this.value.maxHeight + "px";
        // this.extraStyle.width = this.value.maxWidth + "px";

        this.extraStyle.height = "calc(100vh - 120px)";
        this.extraStyle.width = "100%";
        this.inProgress = false;
      }, 750);
    },
    minimize() {
      this.extraStyle.height = this.value.height + "px";

      this.extraStyle.width = this.value.width + "px";

      this.extraStyle.top = this.initialPosition.top + "px";
      this.extraStyle.left = this.initialPosition.left + "px";
      this.extraStyle.zIndex = 1;

      setTimeout(() => {
        this.extraStyle.position = undefined;
        delete this.extraStyle.zIndex;
        this.extraStyle.width = "100%";

        this.inProgress = false;
      }, 300);
    },
    onExpand() {
      this.$emit("onExpand", this.value);
    },
    onCollapse() {
      this.$emit("onCollapse", this.value);
    },
    hasSlot(slotName) {
      return !!this.$scopedSlots[slotName];
    },
    onSelect(e) {
      this.$emit("onActivate", e);
    },
    onClick() {
      this.$emit("onAdd");
    },
    MakeMain() {
      if (this.appOpened) {
        this.appOpened = false;
        this.x = this.preX;
        this.y = this.preY;

        this.width = 50;
        this.height = 50;
      } else {
        this.appOpened = true;

        this.preX = this.x;
        this.preY = this.y;

        this.x = 0;
        this.y = 0;
        this.width = 100;
        this.height = 100;
      }
    },

    openFullScreen() {
      this.value.height = 1200;
      // this.fullscreen = !this.fullscreen;
    },
  },
  watch: {
    hovered(newVal) {
      if (newVal) this.$emit("onHover");
    },
    value: {
      handler(newVal) {
        // this.extraStyle.width = newVal.width + "px";
        this.extraStyle.height = newVal.height + "px";

        this.extraStyle.left = newVal.left + "px";
        this.extraStyle.top = newVal.top + "px";
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.fullscreen {
  // transition: all 0.4s;
  // -webkit-transition: all 0.4s;
  width: 100%;
  // position: relative;
  overflow: auto;
  &.maximized {
    // height: 100% !important;
    // width: 100%;
    position: fixed !important;
    z-index: 9 !important;
  }
  &.hover {
  }
}
.a-view-container {
  height: 100%;
  width: 100%;
  overflow: hidden;

  .a-view {
    // height: 100%;
    width: 100%;
    overflow: hidden;
    // background: rgb(79,79,79,0.3);
    background: transparent;
    backdrop-filter: blur(15px);
    background: radial-gradient(
      circle,
      rgba(2, 26, 40, 0.5) 0%,
      rgba(2, 26, 40, 0.6) 50%,
      rgba(2, 26, 40, 0.7) 63%
    );
    // transition: all 0.3s;
    // -webkit-transition: all 0.3s;
    z-index: 1;

    &::after {
      content: "";
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      z-index: -1;
      background: radial-gradient(
        circle,
        rgba(205, 48, 59, 0.2) 0%,
        rgba(205, 48, 59, 0.2) 50%,
        rgba(205, 48, 59, 0.3) 63%
      );
      opacity: 0;
      transition: all 0.3s;
      -webkit-transition: all 0.3s;
    }

    border: 1px solid #3c4454;
    &.hover {
      // border-top: 4px solid rgba(171, 64, 64, 0.9);

      &::after {
        content: "";
        opacity: 0.2;
      }
    }
    &.maximized {
      // background: black;
      backdrop-filter: blur(30px);
      height: 100;

      &.hover {
        &::after {
          background: radial-gradient(
            circle,
            rgba(255, 255, 255, 0.1) 0%,
            rgba(255, 255, 255, 0.1) 50%,
            rgba(255, 255, 255, 0.1) 63%
          ) !important;
        }
      }
    }

    .a-view-name {
      max-width: 40%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .a-view-header {
      position: absolute;
      width: 100%;
      z-index: 9;
      transition: all 0.3s;
      -webkit-transition: all 0.3s;

      background: radial-gradient(
        circle,
        rgba(0, 0, 0, 0.1) 0%,
        rgba(0, 0, 0, 0.3) 50%,
        rgba(0, 0, 0, 0.5) 63%
      );
      &::after {
        content: "";
        height: 2px;
        width: 100%;
        position: absolute;
        top: 0;
        z-index: -1;
        transform: translateX(-100%);
        background: radial-gradient(
          circle,
          rgba(205, 48, 59, 0.5) 0%,
          rgba(205, 48, 59, 0.6) 50%,
          rgba(205, 48, 59, 0.6) 63%
        );
        transition: all 0.3s;
        -webkit-transition: all 0.3s;
      }

      &.active {
        &::after {
          content: "";
          transform: translateX(0);
        }
      }

      &.small {
        height: 100% !important;
        background: radial-gradient(
          circle,
          rgba(0, 0, 0, 0.9) 0%,
          rgba(0, 0, 0, 0.9) 50%,
          rgba(0, 0, 0, 1) 99%
        );
      }

      .filters-active {
        background-color: red;
        height: 6px;
        width: 6px;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        margin-left: 12px;
        top: 4px;
      }

      .a-view-actions-activator {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .app-bar-space {
      width: 100%;
    }

    .a-view-main-content {
      // overflow: auto;
      // padding-top: 50px!important;
      height: calc(100% - 24px);
      margin-top: 24px;
      // padding: 4px;
      padding: 0px !important;

      .content-wrapper {
        overflow: auto;
        // padding-top: 50px!important;
        height: 100%;
        margin: 0px;
        // padding: 4px;
        padding: 0px !important;
      }
    }
    .loader-placeholder {
      z-index: 999;
      position: absolute;
      top: 0;
      width: 100%;
    }
    .a-view-actions {
      background: rgba(0, 0, 0, 0.4);

      position: absolute;
      width: 100%;
      bottom: 0;
      z-index: 9;

      &.hovered {
        z-index: 9;
      }
    }

    .a-view-actions-container {
      height: 100%;
      width: 100%;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 24px;
      background: rgba(14, 23, 33, 0.8);
      backdrop-filter: blur(1px);
      z-index: 9;

      .actions-wrapper {
        position: relative;
        .action-description {
          position: absolute;
          top: -50px;
        }
      }
    }
  }
}
</style>


