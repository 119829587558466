<template>
  <v-btn
    @click="() => $emit('click')"
    class="mx-2 a-view-action-btn"
    fab
    dark
    small
    @mouseover="() => $emit('mouseover')"
    @mouseleave="() => $emit('mouseleave')"
    :class="{
      active,
    }"
    :style="{
      background: `
      radial-gradient(
        circle,
        rgba(0, 0, 0, 0.1) 0%,
        ${hexToRgba(value.color, 0.3)} 100%
      )
      `,
    }"
  >
    <v-icon dark>
      {{ value.icon }}
    </v-icon>
  </v-btn>
</template>
  
  
  <script>
import hexToRgba from "hex-to-rgba";

export default {
  name: "TreeViewDesktop",
  props: {
    value: {
      default: () => ({}),
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hexToRgba,
      user: {},
    };
  },
};
</script>
  
  
  <style lang="scss" scoped>
.a-view-action-btn {
  border-radius: 14px;
  transition: all 0.2s;
  height: 50px;
  width: 50px;

  &:hover {
    transform: scale(1.2);
  }
  &.active {
    transform: scale(1.2);
  }
}
</style>